import React, { useEffect } from "react";

import { Row, Col, Button, Progress, Card } from "antd";

import PageTitle from "../../../layout/components/content/page-title";
import themeConfig from "../../../configs/themeConfig";
import { Link } from "react-router-dom";
import { RiBankCard2Line } from "react-icons/ri";
import BottomMenu from "../../../layout/components/bottom-menu";

export default function Pages() {
  useEffect(() => {
    document.title = "Dashboard - " + themeConfig.appname;
  }, []);

  return (
    <>
      <PageTitle pageTitle="Dashboard" />

      <div className="hp-content-main">
        <Row gutter={[32, 32]}></Row>
      </div>

      <BottomMenu active="home" />
    </>
  );
}

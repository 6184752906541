import { Category, Paper, People } from "react-iconly";

const pages = [
  {
    header: "Pages",
  },
  {
    id: "dashboard",
    title: "Dashboard",
    icon: <Category set="curved" className="remix-icon" />,
    navLink: "/dashboard",
  },
  {
    id: "purchase-history",
    title: "Riwayat Pembelian",
    icon: <Paper set="curved" className="remix-icon" />,
    navLink: "/purchase/data",
  },
  {
    id: "user-management",
    title: "Manajemen User",
    icon: <People set="curved" className="remix-icon" />,
    navLink: "/user/data",
  },
];

export default pages;

import dataListConfig from "../../configs/dataListConfig";
import * as actionTypes from "./dataListTypes";

const INITIAL_STATE = {
  userTrial: dataListConfig.userTrial,
  userActive: dataListConfig.userActive,
  userExpired: dataListConfig.userExpired,
};

const dataListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_DATA_LIST_TYPE:
      return {
        ...state,
        [action.payload.dataKey]: action.payload.value,
      };

    default:
      return state;
  }
};

export default dataListReducer;

import { lazy } from "react";

const PagesRoutes = [
  // Authentication
  {
    path: "/login",
    component: lazy(() => import("../../view/pages/auth/login")),
    layout: "FullLayout",
    auth: false,
  },
  {
    path: "/forgot-password",
    component: lazy(() => import("../../view/pages/auth/forgot-password")),
    layout: "FullLayout",
    auth: false,
  },
  {
    path: "/reset-password",
    component: lazy(() => import("../../view/pages/auth/reset-password")),
    layout: "FullLayout",
    auth: false,
  },

  // Main Pages
  {
    path: "/dashboard",
    exact: true,
    component: lazy(() => import("../../view/pages/dashboard")),
    layout: "VerticalLayout",
    auth: true,
  },
  // Package
  {
    path: "/purchase/data",
    component: lazy(() => import("../../view/pages/purchase/data")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/purchase/form",
    component: lazy(() => import("../../view/pages/purchase/form")),
    layout: "VerticalLayout",
    auth: true,
  },

  // User
  {
    path: "/user/data",
    component: lazy(() => import("../../view/pages/user/data")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/user/form",
    component: lazy(() => import("../../view/pages/user/form")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/user/detail",
    component: lazy(() => import("../../view/pages/user/detail/detail")),
    layout: "VerticalLayout",
    auth: true,
  },
];

export default PagesRoutes;
